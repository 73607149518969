import React from 'react'
import Helmet from "react-helmet"
import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
      <Helmet>
            <title>expresscv Blog</title>
            <meta
              name="description"
              content="Resume templates approved by recruitment pros. The best you'll see online."
            />
            <meta property="og:type" content="article"/>
            <meta property="og:url" content="https://express-cv.com/blog/"/>
            <meta property="og:title" content="expresscv Blog"/>
            <meta property="og:description" content="Resume templates approved by recruitment pros. The best you'll see online."/>
            <meta property="og:image" content="https://express-cv.com/img/blog-index.webp"/>
          </Helmet>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/blog-index.webp')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow: '0.5rem 0 0 #363636, -0.5rem 0 0 #363636',
              color: 'white',
              padding: '1rem',
            }}
          >
            Latest Stories
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
